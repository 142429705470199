import Script from 'next/script';
import * as React from 'react';

import { DesktopSidebarContext } from './Desktop';
import { useSecondaryNavItems } from './helpers';
import HelpMenu from './HelpMenu';
import NavLink from './NavLink';

export default function SecondaryNav() {
  const { isCollapsed } = React.useContext(DesktopSidebarContext);

  return (
    <>
      <Script
        id="changeLog"
        dangerouslySetInnerHTML={{
          __html: `
        HW_config = {
          selector: '.hw_updates',
          account: 'vJPbQx',
          translations: {
            title: 'Rollbar Changelog',
            readMore: 'Read more',
          },
        }
      `,
        }}
      />
      <Script strategy="lazyOnload" src="//cdn.headwayapp.co/widget.js" />
      {useSecondaryNavItems().map(({ id, name, href, icon: Icon }) => {
        let className;
        if (id === 'nav-changelog') {
          className = isCollapsed ? 'hw_updates is-collapsed' : 'hw_updates';
        }
        return (
          <NavLink
            key={id}
            id={id}
            name={name}
            href={href}
            icon={Icon}
            className={className}
          />
        );
      })}
      <HelpMenu />
      <style global jsx>{`
        #HW_badge_cont {
          z-index: 9 !important;
        }
      `}</style>
    </>
  );
}
