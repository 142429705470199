interface ErrorMessageProps {
  description: string;
  errors: string[];
}

export default function ErrorMessage({
  description,
  errors,
}: ErrorMessageProps) {
  return (
    <>
      <p className="font-semibold mb-1">{description}</p>
      {errors.length == 1 && <p>{errors[0]}</p>}
      {errors.length > 1 && (
        <ul>
          {errors.map((msg, i) => (
            <li key={i}>{msg}</li>
          ))}
        </ul>
      )}
    </>
  );
}
