import React, { useContext } from 'react';

import { AccountInfo } from '@/types/account';

export const AccountInfoContext = React.createContext<AccountInfo>(undefined!);

export function useAccountInfo() {
  const accountInfo = useContext(AccountInfoContext);
  return accountInfo;
}
