import { TeamType } from '@/types/team';

import { apiClient } from './client';

//delete team
export type deleteTeamData = {
  teamId?: string;
};
export type ApiResponseDeleteTeam = {};
export async function deleteTeam({ teamId }: deleteTeamData) {
  const result = await apiClient().delete<ApiResponseDeleteTeam>(
    `/settings/teams/${teamId}`
  );
  return result?.data;
}

//create team
export type createTeamData = { name: string };
export type ApiResponseCreateTeam = { success: true; team: TeamType };
export async function createTeam({ name }: createTeamData) {
  const result = await apiClient().post<ApiResponseCreateTeam>(
    '/settings/teams',
    { name }
  );
  return result?.data;
}
