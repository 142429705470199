import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Fragment } from 'react';

export type SlideOverProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
  children: React.ReactNode;
  showClose?: boolean;
  maxWidth?: string;
  innerOverflow?: string;
  showBgOverlay?: boolean;
  hasTopPadding?: boolean;
  openFromLeft?: boolean;
  stickToLeft?: boolean;
};

export default function SlideOver({
  open,
  showClose = true,
  showBgOverlay = true,
  innerOverflow = 'overflow-y-auto',
  hasTopPadding = true,
  openFromLeft = false,
  stickToLeft = false,
  setOpen,
  children,
  maxWidth,
}: SlideOverProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden z-modal"
        open={open}
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={classNames('absolute inset-0', {
                'bg-gray-500 bg-opacity-75 transition-opacity': showBgOverlay,
              })}
            />
          </Transition.Child>

          <div
            className={classNames(
              'fixed inset-y-0 max-w-full flex',
              stickToLeft ? 'left-0' : 'right-0',
              {
                'pt-10': hasTopPadding,
              }
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom={
                openFromLeft ? '-translate-x-full' : 'translate-x-full'
              }
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo={openFromLeft ? '-translate-x-full' : 'translate-x-full'}
            >
              <div className={classNames('w-screen max-w-screen', maxWidth)}>
                <div
                  className={classNames(
                    'h-full bg-white shadow-xl',
                    innerOverflow
                  )}
                >
                  {showClose && (
                    <div className="absolute top-20 right-6 md:right-20 mr-3 h-7 z-20">
                      <button
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  )}
                  <section className="w-full h-full">{children}</section>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
