import { AnalyticsBrowser } from '@segment/analytics-next';

const writeKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
const cdnURL = process.env.NEXT_PUBLIC_SEGMENT_CDN_URL;
const apiHost = process.env.NEXT_PUBLIC_SEGMENT_API_PROXY;

export const analytics = new AnalyticsBrowser();

// We only want to load the analytics library on the client
// side and only if the write key is set. This lazy loading
// approach could also allow us to get user consent before
// tracking if we wanted to.
if (typeof window !== 'undefined' && writeKey) {
  analytics.load(
    {
      writeKey,
      cdnURL,
    },
    {
      integrations: {
        ...(apiHost && {
          'Segment.io': {
            apiHost,
          },
        }),
      },
    }
  );
}
