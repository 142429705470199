import { useMemo } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';

const BASE_URL = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL;

export default function useMoxWebAccountSettingsUrl(relativeUrl = '') {
  const { accountSlug } = useAccountInfo();
  return useMemo(
    () =>
      `${BASE_URL}/settings/accounts/${accountSlug}/${relativeUrl.replace(
        /^\/+/g,
        ''
      )}`,
    [accountSlug, relativeUrl]
  );
}
