import { AnalyticsBrowser } from '@segment/analytics-next';
import { useContext, useMemo } from 'react';
import React from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { Flags } from '@/lib/flags';
import useFlag from '@/lib/useFlag';

import { analytics } from './analytics';

const AnalyticsContext = React.createContext<AnalyticsBrowser>(analytics);

export function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics() {
  const { accountId, accountSlug, planId, subscribedPlan, user } =
    useAccountInfo();
  const { page, track, identify, group } = useContext(AnalyticsContext);

  const useFullStory = useFlag(Flags.FULLSTORYNEW);

  const analytics = useMemo(() => {
    const { name, id, email, dateCreated } = user;
    const baseProps = {
      name: name,
      accountSlug,
      accountId,
      planId,
      subscribedPlan,

      groupId: accountId,
      userId: id,
      userEmail: email,
      email: email,
      useFullStory: Boolean(useFullStory),
      createdAt: dateCreated,
    };
    return {
      page,
      group,
      identify(userId: string, props?: object) {
        return identify(userId, { ...baseProps, ...props });
      },
      track(event: string, props?: object) {
        return track(event, { ...baseProps, ...props });
      },
    };
  }, [
    user,
    accountSlug,
    accountId,
    planId,
    subscribedPlan,
    useFullStory,
    page,
    identify,
    group,
    track,
  ]);
  return analytics;
}
