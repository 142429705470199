import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

import { gqlClient } from '@/api/gqlClient';
import {
  useUserAccountsQuery,
  useAccountSubscriptionClearDeactivationMutation,
} from '@/generated/graphql';

type AccountSwitchProps = {
  selectedAccount: string;
};
export default function AccountSwitch({ selectedAccount }: AccountSwitchProps) {
  const { data: accounts } = useUserAccountsQuery(gqlClient(), {});
  const { mutate: clearDeactivationDate } =
    useAccountSubscriptionClearDeactivationMutation(gqlClient());
  let accountsList = accounts?.viewer.accounts.edges.map(({ node }: any) => ({
    id: node.id,
    slug: node.slug,
  }));
  const baseUrl = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL;

  return (
    <>
      <div className="h-2.5 w-2.5 absolute bg-white left-1 bottom-4 rotate-45 transform" />
      <div className="w-48 pb-2 bg-white shadow-md rounded-sm">
        {accountsList &&
          accountsList.map((account: any) => (
            <div
              key={account.id}
              className={classNames(
                {
                  'bg-gray-100 hover:bg-white':
                    selectedAccount === account.slug,
                },
                'max-h-48 text-xs p-2 overflow-scroll hover:text-blue-600'
              )}
            >
              <a
                href={`${baseUrl}/${account.slug}`}
                onClick={() => {
                  clearDeactivationDate({ accountId: account.id });
                }}
              >
                {selectedAccount === account.slug ? (
                  <div className="flex flex-row justify-between">
                    <b>{account.slug}</b>
                    <CheckIcon className="h-3 text-blue-600" />
                  </div>
                ) : (
                  <p>{account.slug}</p>
                )}
              </a>
            </div>
          ))}
        <a
          className="text-xs p-2 font-medium hover:text-blue-600"
          href={`${baseUrl}/account/create`}
        >
          + Create New Account
        </a>
      </div>
    </>
  );
}
