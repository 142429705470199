export const PAGE_TITLES = {
  AUTHENTICATION: 'Authentication',
  ACCESS_TOKEN: 'Access Token',
  BILLING_INFO: 'Billing Info',
  CONNECT: 'Connect',
  CONNECTED_ACCOUNTS: 'Connected Accounts',
  DATA_PROCESSING: 'Data Processing Addendum',
  DATA_RETENTION: 'Data Retention',
  DEFAULT: 'Rollbar',
  EMAIL_SETTINGS: 'Email Settings',
  FIX: 'Fix',
  GENERAL: 'General',
  IMPROVE: 'Improve',
  INVOICE: 'Invoice',
  INVOICES: 'Invoices',
  MANAGE_USER: 'Manage User',
  PEOPLE: 'Affected People',
  PLAN: 'Your Plan',
  PROJECTS: 'Projects',
  PROJECTS_ACCESS_TOKENS: 'Project Access Tokens',
  RQL: 'RQL',
  SAML: 'SAML Identity Provider',
  SUBSCRIPTION: 'Subscription',
  TEAMS: 'Teams',
  THIRD_PARTY_DATA_ACCESSS: '3rd party Data Access',
  USAGE: 'Usage',
  USERS: 'Users',
  USER_STATUS: 'User Status',
  COMPARE: 'Compare Plans',
};

export const LOGIN_PATH = 'login';
export const AUTHENTICATION_COOKIE_NAME = 'auth_tkt';
