import { AxiosRequestConfig } from 'axios';
import { snakeCase } from 'change-case';
import snakecaseKeys from 'snakecase-keys';

import { convertUrlSearchParams } from './convertUrlSearchParams';

export function snakeCaseRequestInterceptor(
  config: AxiosRequestConfig
): AxiosRequestConfig {
  if (typeof config.data === 'object') {
    config.data = snakecaseKeys(config.data);
  }
  if (config.data instanceof URLSearchParams) {
    config.data = convertUrlSearchParams(config.data, snakeCase);
  }
  if (typeof config.params === 'object') {
    config.params = snakecaseKeys(config.params);
  }
  if (config.params instanceof URLSearchParams) {
    config.params = convertUrlSearchParams(config.params, snakeCase);
  }
  return config;
}
