import { usePrimaryNavItems } from './helpers';
import NavLink from './NavLink';

export default function PrimaryNav() {
  return (
    <>
      {usePrimaryNavItems().map((navItem) => {
        return <NavLink key={navItem.id} {...navItem} />;
      })}
    </>
  );
}
