import { ReactNode } from 'react';

import { CurrentPlanCard } from '@/components/common/CurrentPlanCard';
import { UpsellFeature } from '@/generated/graphql';
import Modal from '@/rollbar-ui/components/Modal/Modal';

import { UpgradePlanCard } from './UpgradePlanCard';

type UpsellModalProps = {
  onClose: () => void;
  FeatureCard: ReactNode;
  upsellFeature?: UpsellFeature;
};

const UpsellModalTitle = () => (
  <h1 className="text-blue-700 text-2xl">
    Get the Most Out of Rollbar with Premium Tools
  </h1>
);

export default function UpsellModal({
  onClose,
  FeatureCard,
  upsellFeature,
}: UpsellModalProps) {
  return (
    <Modal
      hasCloseButton={true}
      isOpen={true}
      onCancel={onClose}
      title={<UpsellModalTitle />}
      modalClassName="!max-w-4xl bg-gray-100"
      showFooter={false}
    >
      <div className="flex flex-col gap-y-6">
        <div className="hidden sm:flex">{FeatureCard}</div>

        <div className="gap-x-6 grid sm:grid-cols-2">
          <CurrentPlanCard className="hidden sm:flex" />

          {upsellFeature && (
            <UpgradePlanCard onClose={onClose} upsellFeature={upsellFeature} />
          )}
        </div>
      </div>
    </Modal>
  );
}
