import { apiClient } from './client';

const ENDPOINT_SAVE_SIGN_UP_MODE = '/settings/signUpMode';

export type ApiResponseSaveSignUpMode = {
  success: boolean;
  message: string;
};

export type SaveSignUpModeData = {
  emailDomainSafeList: string;
  defaultTeam: string | null;
};

export async function saveSignUpMode(
  data: SaveSignUpModeData
): Promise<ApiResponseSaveSignUpMode> {
  const result = await apiClient().post(ENDPOINT_SAVE_SIGN_UP_MODE, data);
  return result.data;
}

export type ApiResponseSaveUserInvitation = {
  success: boolean;
};

export type SaveUserInvitationData = {
  nonOwnersInvitations: boolean;
  accountSlug: string;
};

export async function saveUserInvitation(
  data: SaveUserInvitationData
): Promise<ApiResponseSaveUserInvitation> {
  const { nonOwnersInvitations, accountSlug } = data;
  const result = await apiClient().post(
    `/settings/accounts/${accountSlug}/user_invitations`,
    { nonOwnersInvitations }
  );
  return result.data;
}

export type ApiResponseAllowNonOwnersCreateProject = {
  success: boolean;
};

export type AllowNonOwnersCreateProjectData = {
  nonOwnersCreateProject: boolean;
  accountSlug: string;
};

export async function allowNonOwnersCreateProject(
  data: AllowNonOwnersCreateProjectData
): Promise<ApiResponseAllowNonOwnersCreateProject> {
  const { nonOwnersCreateProject, accountSlug } = data;
  const result = await apiClient().post(
    `/settings/accounts/${accountSlug}/allow_non_owners_create_project`,
    { nonOwnersCreateProject }
  );
  return result.data;
}

const ENDPOINT_CONVERT_TO_ORGANIZATION = '/settings/organization';
export type ApiResponseConvertToOrganization = {
  success: boolean;
};

export type ConvertToOrganizationData = {
  name: string;
};

export async function ConvertToOrganization(
  data: ConvertToOrganizationData
): Promise<ApiResponseConvertToOrganization> {
  const result = await apiClient().post(ENDPOINT_CONVERT_TO_ORGANIZATION, data);
  return result.data;
}

const ENDPOINT_SAVE_ACCOUNT_DETAIL = '/settings/accountDetail';
export type ApiResponseSaveAccountDetail = {
  success: boolean;
};

export type SaveAccountDetailData = {
  accountName: string;
  description: string;
};

export async function SaveAccountDetail(
  data: SaveAccountDetailData
): Promise<ApiResponseSaveAccountDetail> {
  const result = await apiClient().post(ENDPOINT_SAVE_ACCOUNT_DETAIL, data);
  return result.data;
}

export async function DeleteAccount({
  accountSlug,
}: {
  accountSlug: string;
}): Promise<null> {
  await apiClient().delete(`/settings/${accountSlug}`);
  return null;
}
