export function convertUrlSearchParams(
  paramData: URLSearchParams,
  nameConverter: (name: string) => string
): URLSearchParams {
  const rv = new URLSearchParams();
  for (const [p, val] of paramData) {
    rv.set(nameConverter(p), val);
  }
  return rv;
}
