import {
  ChartBarIcon,
  MegaphoneIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/24/outline';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { Flags } from '@/lib/flags';
import useAccountUrl from '@/lib/useAccountUrl';
import useFlag from '@/lib/useFlag';

import * as Icons from '../icons';

const baseUrl = process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL;
const ACCOUNT_REGEX = /^\/a\/[a-zA-Z0-9][a-zA-Z0-9-_.]*(\/*)$/;

export function defaultShouldHighlightNavItem(path: string, href: string) {
  const pathContainsHref = path.toLowerCase().startsWith(href.toLowerCase());
  return pathContainsHref;
}

export type NavItem = {
  id: string;
  name: string;
  href: string;
  icon: any;
  // return true if the current path should result in the nav item being highlighted
  // if `shouldHighlight` is not provided, will default to just checking if
  // the current path starts with the provided href, to highlight on nested routes.
  shouldHighlight?: (currentPath: string) => boolean;
};

export function usePrimaryNavItems(): NavItem[] {
  const { accountSlug, user } = useAccountInfo();
  const improve = useFlag(Flags.IMPROVE);
  const olympusPeopleNav = useFlag(Flags.OLYMPUS_PEOPLE_NAV);
  const olympusDashboardNav = useFlag(Flags.WELCOME_DASHBOARD);
  const settingsUrl = useAccountUrl('settings');
  const peopleUrl = useAccountUrl('people');
  const improveUrl = useAccountUrl('improve');
  const projectsUrl = useAccountUrl('projects');
  const itemsUrl = useAccountUrl('fix/items');
  const analyzeUrl = useAccountUrl('analyze');
  const dashboardUrl = useAccountUrl();

  const generalSettingsFlag = useFlag(Flags.OLYMPUS_GENERAL_SETTINGS);

  const generalSettingsUrl = generalSettingsFlag
    ? settingsUrl
    : `${baseUrl}/settings/accounts/${accountSlug}/`;

  const peopleNavLink = olympusPeopleNav
    ? peopleUrl
    : `${baseUrl}/${accountSlug}/all/people`;

  const dashboardNavLink = olympusDashboardNav
    ? dashboardUrl.slice(0, -1) // strip off the trailing slash that useAccountUrl adds -_-
    : `${baseUrl}/${accountSlug}/`;

  return [
    {
      id: 'nav-dashboard',
      name: 'Dashboard',
      href: dashboardNavLink,
      icon: Icons.Dashboard,
      shouldHighlight: (currentPath) => {
        return !!currentPath.split('?')[0].match(ACCOUNT_REGEX);
      },
    },
    {
      id: 'nav-itemList',
      name: 'Items',
      href: itemsUrl,
      icon: Icons.ItemList,
      shouldHighlight: (currentPath) => {
        const isItemDetailsPage = currentPath.includes('/fix/item');
        return (
          defaultShouldHighlightNavItem(currentPath, itemsUrl) ||
          isItemDetailsPage
        );
      },
    },
    {
      id: 'nav-versions',
      name: 'Versions',
      href: `${baseUrl}/${accountSlug}/all/versions`,
      icon: Icons.Versions,
    },
    {
      id: 'nav-deploys',
      name: 'Deploys',
      href: `${baseUrl}/${accountSlug}/all/deploys`,
      icon: Icons.Deploys,
    },
    ...(improve
      ? [
          {
            id: 'nav-improve',
            name: 'Improve',
            href: improveUrl,
            icon: ChartBarIcon,
          },
        ]
      : []),
    {
      id: 'nav-analyze',
      name: 'Analyze',
      href: analyzeUrl,
      icon: Icons.RQL,
    },
    {
      id: 'nav-people',
      name: 'People',
      href: peopleNavLink,
      icon: Icons.People,
    },
    {
      id: 'nav-projectSettings',
      name: 'Projects',
      href: projectsUrl,
      icon: Icons.Projects,
    },
    ...(user.isOwner || user.isSuperadmin
      ? [
          {
            id: 'nav-accountSettings',
            name: 'Settings',
            href: generalSettingsUrl,
            icon: Icons.Settings,
          },
        ]
      : []),
  ];
}

export function useSecondaryNavItems(): NavItem[] {
  const { accountSlug, isEnterprisePlan, user } = useAccountInfo();
  const upgradeNavUrl = useAccountUrl('settings/subscriptions/change');

  return [
    {
      id: 'nav-changelog',
      name: 'Updates',
      href: 'https://changelog.rollbar.com/',
      icon: MegaphoneIcon,
    },
    ...(!isEnterprisePlan && !user.isGuest
      ? [
          {
            id: 'nav-upgrade',
            name: 'Upgrade',
            href: upgradeNavUrl,
            icon: ShieldExclamationIcon,
          },
        ]
      : []),
  ];
}
