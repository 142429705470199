import {
  ComponentPropsWithoutRef,
  createContext,
  ElementType,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import Toast, { ToastProps } from '@/rollbar-ui/Toast';

let id = 1;

type ToastObj = { id: number; content: ReactNode } & ToastProps;

type ToastsContextProps = {
  addToast: (toast: Partial<ToastProps>) => number;
  removeToast: (id: number) => void;
};

const ToastsContext = createContext<ToastsContextProps>({
  addToast: () => 0,
  removeToast: () => {},
});

export default function ToastsContextProvider({
  children,
}: ComponentPropsWithoutRef<ElementType>) {
  const portalNode = useRef<HTMLElement>();

  useEffect(() => {
    portalNode.current =
      document.getElementById('toast-portal-root') || undefined;
  }, []);

  const [toasts, setToasts] = useState<ToastObj[]>([]);

  const addToast = useCallback(
    (newToast) => {
      id++;
      setToasts((toasts) => [{ id, ...newToast }, ...toasts]);
      return id;
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (id) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts]
  );

  return (
    <ToastsContext.Provider value={{ addToast, removeToast }}>
      {children}
      {portalNode.current &&
        createPortal(
          <>
            <div className="left-6 bottom-6 absolute transform max-w-lg z-toast">
              {toasts.map((toast: ToastObj) => (
                <Toast
                  {...toast}
                  onClose={() => removeToast(toast.id)}
                  key={toast.id}
                >
                  {toast.content}
                </Toast>
              ))}
            </div>
          </>,
          portalNode.current
        )}
    </ToastsContext.Provider>
  );
}

export function useToasts(): ToastsContextProps {
  return useContext(ToastsContext);
}
