import { ClientRequest } from 'http';

import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';

let client: GraphQLClient;
const endpoint =
  process.env.NEXT_PUBLIC_GQL_ENDPOINT ?? 'http://localhost:4000/graphql';

function getHeaders(headerData?: {
  ip?: string | null;
  cookie?: string | null;
}): { headers: HeadersInit } | undefined {
  const { ip, cookie } = headerData || {};
  const gqlEndpointIsLocal = endpoint && !!new URL(endpoint).port;
  const authToken =
    gqlEndpointIsLocal && process.env.NEXT_PUBLIC_JWT
      ? {
          authorization: `Bearer ${process.env.NEXT_PUBLIC_JWT}`,
        }
      : undefined;

  if (!cookie && !authToken) return;

  return {
    headers: {
      ...authToken,
      ...(ip ? { 'x-forwarded-for': ip } : undefined),
      ...(cookie ? { Cookie: cookie } : undefined),
    },
  };
}

export function gqlClient(): GraphQLClient {
  if (!client) {
    const clientOptions: RequestInit = {
      credentials: 'include',
      mode: 'cors',
      ...getHeaders(),
    };
    client = new GraphQLClient(endpoint, clientOptions);
  }

  return client;
}

// We can't use a cached client on the server because there are multiple
// users
export function gqlServerClient({
  ip,
  cookie,
}: {
  ip: string | null;
  cookie: string | undefined;
}): GraphQLClient {
  const clientOptions: RequestInit = {
    credentials: 'include',
    ...getHeaders({ ip, cookie }),
  };

  return new GraphQLClient(endpoint, clientOptions);
}
