import Announcements from './announcements.svg';
import Dashboard from './dashboard.svg';
import Deploys from './deploys.svg';
import ItemList from './item-list.svg';
import People from './people.svg';
import Projects from './projects.svg';
import RQL from './rql.svg';
import Settings from './settings.svg';
import Support from './support.svg';
import Versions from './versions.svg';

export {
  Announcements,
  Dashboard,
  Deploys,
  ItemList,
  People,
  Projects,
  RQL,
  Settings,
  Support,
  Versions,
};
