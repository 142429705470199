import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { analytics } from '@/lib/analytics/analytics';

export function Analytics() {
  const router = useRouter();
  const accountInfo = useAccountInfo();

  const route = router.route;
  const logPage = useCallback(() => {
    analytics.page({
      route,
    });
  }, [route]);

  useEffect(() => {
    logPage();

    router.events.on('routeChangeComplete', logPage);

    return () => {
      router.events.off('routeChangeComplete', logPage);
    };
  }, [router.events, logPage]);

  // User details
  const name = accountInfo?.user.name;
  const userId = accountInfo?.user.id.toString();
  const userEmail = accountInfo?.user.email;
  const createdAt = accountInfo?.user.dateCreated;

  // Account details
  const accountSlug = accountInfo?.accountSlug;
  const accountId = accountInfo?.accountId;
  const planId = accountInfo?.planId;
  const subscribedPlan = accountInfo?.subscribedPlan;

  useEffect(() => {
    if (userId) {
      analytics.identify(userId, {
        name,
        userId,
        userEmail,
        email: userEmail,
        createdAt,

        // TODO: verify with @ZD that these can be removed
        accountSlug,
        accountId,
        planId,
        subscribedPlan,

        groupId: accountId,
      });
    }
  }, [
    accountId,
    accountSlug,
    createdAt,
    name,
    planId,
    subscribedPlan,
    userEmail,
    userId,
  ]);

  useEffect(() => {
    if (accountSlug) {
      analytics.group(accountSlug, {
        accountId: accountId,
        accountSlug: accountSlug,
        planId: planId,
        subscribedPlan: subscribedPlan,
      });
    }
  }, [accountId, accountSlug, planId, subscribedPlan]);

  return null;
}
