import { apiClient } from '../client';

const ENDPOINT_INVITE_A_FRIEND = '/projects/:projectId/invite';

export type ApiResponseInviteFriend = {
  status: number;
};
export type InviteFriendProjectParams = {
  projectId: number;
  accountSlug: string;
  fromEmail: string;
  toEmail: string;
  message?: string;
};

export async function inviteFriend(data: InviteFriendProjectParams) {
  const { projectId, accountSlug, fromEmail, toEmail, message } = data;
  return apiClient().post<ApiResponseInviteFriend>(ENDPOINT_INVITE_A_FRIEND, {
    projectId,
    accountSlug,
    fromEmail,
    toEmail,
    message,
  });
}
