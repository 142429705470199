import { Status } from '@/types/common';
import {
  ProjectType,
  ProjectAccessTokenScope,
  ProjectAccessToken,
} from '@/types/projects';

import { apiClient } from './client';

const debug = require('@/lib/debug');
const log = debug.extend('api:projects');

const ENDPOINT_PROJECTS = '/account/:accountSlug/projects';

interface ApiResponseProjectsList {
  success: boolean;
  projects: ProjectType[];
}

export async function getProjects(accountSlug: string): Promise<ProjectType[]> {
  if (!accountSlug) {
    throw new Error('must include accountSlug when fetching projects');
  }
  log('getting projects from accountSlug: %s', accountSlug);
  const params = { accountSlug };
  const result = await apiClient()
    .get<ApiResponseProjectsList>(ENDPOINT_PROJECTS, { params })
    .catch((error) => {
      throw error;
    });
  const { projects } = result?.data;
  return projects;
}

const ENDPOINT_PROJECT_ACCESS_TOKENS = '/projects/:projectId/access_tokens';

interface ApiResponseAccessToken {
  projectId: number;
  accessToken: string;
  name: string;
  status: Status;
  rateLimitWindowSize: number;
  rateLimitWindowCount: number;
  curRateLimitWindowStart: number;
  curRateLimitWindowCount: number;
  dateCreated: number;
  dateModified: number;
  scopes: ProjectAccessTokenScope[];
}

interface ApiResponseProjectAccessTokensList {
  result: [ApiResponseAccessToken];
}

function convertSecondsToDate(seconds: number): Date {
  return new Date(seconds * 1000);
}

function convertResponseToProjectAccessToken(
  apiToken: ApiResponseAccessToken
): ProjectAccessToken {
  return {
    ...apiToken,
    curRateLimitWindowStart: convertSecondsToDate(
      apiToken.curRateLimitWindowStart
    ),
    dateCreated: convertSecondsToDate(apiToken.dateCreated),
    dateModified: convertSecondsToDate(apiToken.dateModified),
    scopes: apiToken.scopes,
  };
}

export async function getProjectAccessTokens(
  projectId: number
): Promise<ProjectAccessToken[]> {
  const params = { projectId };
  const result = await apiClient().get<ApiResponseProjectAccessTokensList>(
    ENDPOINT_PROJECT_ACCESS_TOKENS,
    {
      params,
    }
  );
  const { result: apiAccessTokens } = result.data;
  return apiAccessTokens.map(convertResponseToProjectAccessToken);
}

const ENDPOINT_DELETE_PROJECT = '/projects/:projectId';

export type ApiResponseDeleteProject = {
  status: number;
};

export type DeleteProjectParams = {
  projectId: number;
};

export async function deleteProject(params: DeleteProjectParams) {
  return await apiClient().delete<ApiResponseDeleteProject>(
    ENDPOINT_DELETE_PROJECT,
    { params }
  );
}

const ENDPOINT_PROJECT_STATUS = '/projects/:projectId/status';

export type ApiResponseToggleProjectStatus = {
  status: number;
};

export type ToggleProjectStatusParams = {
  projectId: number;
  status: 'enabled' | 'disabled';
};

export async function toggleProjectStatus(data: ToggleProjectStatusParams) {
  const { projectId, status } = data;
  return apiClient().post<ApiResponseToggleProjectStatus>(
    ENDPOINT_PROJECT_STATUS,
    {
      projectId,
      status,
    }
  );
}
