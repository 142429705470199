import { createContext, useContext } from 'react';

import { UpsellContext } from './types';

const context = createContext<UpsellContext>({
  setShowModal: () => {},
  showModal: false,
  setFeatureCard: () => {},
  FeatureCard: undefined,
  upsellFeature: undefined,
  setUpsellFeature: () => {},
});

const useUpsell = () => {
  const { showModal, setShowModal } = useContext(context);
  const { FeatureCard, setFeatureCard } = useContext(context);
  const { upsellFeature, setUpsellFeature } = useContext(context);

  return {
    context,
    setShowModal,
    showModal,
    FeatureCard,
    setFeatureCard,
    upsellFeature,
    setUpsellFeature,
  };
};

export default useUpsell;
