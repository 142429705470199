import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';

import { Flags } from './flags';

export default function useFlag<T = boolean>(flag: Flags): T | undefined {
  const { [flag]: onFeatureFlag } = useLDFlags();
  return onFeatureFlag;
}

export function useJSONFlag(flag: Flags): any | undefined {
  const { [flag]: onFeatureFlag } = useLDFlags();
  return onFeatureFlag;
}
