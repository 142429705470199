import { AccountInfo } from '@/types/account';

export function getClientConfig(accountInfo: AccountInfo) {
  const { id, name, email, hash } = accountInfo.user;
  return {
    kind: 'user',
    key: String(id),
    hash,
    name,
    email,
    account_id: accountInfo.accountId,
    account_slug: accountInfo.accountSlug,
    normalized_account_slug: accountInfo.accountSlug.toLowerCase(),
    subscribed_plan: accountInfo.subscribedPlan,
    planId: accountInfo.planId,
    during_free_trial: accountInfo.isFreeTrial,
    // we should deprecate `on_free_plan` when we have the free plan being passed under plan_id
    on_free_plan: accountInfo.isFreePlan,
  };
}
