import { UpsellFeature } from '@/generated/graphql';
import { getFeaturesByPlan, planData, PlanType } from '@/lib/utils';

interface GetPlanById {
  ({ id }: { id: string }):
    | {
        name: string;
        planId: string;
        includedOccurrences: number;
        monthlyCost: number;
        annualCost: number;
        costPerThousand: number;
        type: PlanType;
      }
    | undefined;
}

export const getUpgradePlanById: GetPlanById = ({ id }) => {
  let foundCurrentPlan = false;

  for (const [planType, plans] of Object.entries(planData)) {
    if (Array.isArray(plans)) {
      if (foundCurrentPlan) {
        return { ...plans[0].plan, type: planType as PlanType };
      }

      foundCurrentPlan = Boolean(plans.find(({ plan }) => plan.planId === id));

      continue;
    } else {
      const { plan } = plans;

      if (plan.planId === id) {
        foundCurrentPlan = true;

        continue;
      }

      if (foundCurrentPlan) {
        return {
          ...plan,
          type: planType as PlanType,
        };
      }
    }
  }
};

export const getUpgradePlanFeatureList = ({
  activePlanId,
  upsellFeature,
}: {
  activePlanId: string;
  upsellFeature: UpsellFeature;
}) => {
  {
    const upgradePlan = getUpgradePlanById({ id: activePlanId });
    if (!upgradePlan) {
      throw new Error('Unable to find an upgrade plan');
    }
    const { type } = upgradePlan;
    const upgradedPlanFeaturesList = getFeaturesByPlan({ type }).filter(
      ({ name, value: isActiveForPlanType, lowestAvailablePlanType }) =>
        isActiveForPlanType &&
        // event volume is handled separately
        name !== 'Included event volume' &&
        lowestAvailablePlanType == type
    );

    const featureToHighlightIndex = upgradedPlanFeaturesList.findIndex(
      ({ name }) =>
        name.toLowerCase().includes(upsellFeature.toString().toLowerCase())
    );
    const featureToHighlight =
      featureToHighlightIndex >= 0 &&
      upgradedPlanFeaturesList.splice(featureToHighlightIndex, 1)[0];

    const otherFeatures = upgradedPlanFeaturesList
      .slice(
        0,
        // if for some reason, the upsell feature wasn't found in the list, just return the first 5
        !!featureToHighlight ? 3 : 4
      )
      .map(({ name }) => ({ name }));
    const initialFeatures: { name: string; highlighted?: boolean }[] = [
      {
        name: `${
          upgradePlan.includedOccurrences / 1000
        }K occurrences per month`,
      },
    ];

    featureToHighlight &&
      initialFeatures.push({
        name: featureToHighlight.name,
        highlighted: true,
      });

    return initialFeatures.concat(otherFeatures);
  }
};
