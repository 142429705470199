import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';

import Tooltip from '@/rollbar-ui/Tooltip';

import { DesktopSidebarContext } from './Desktop';
import { NavItem, defaultShouldHighlightNavItem } from './helpers';

export type NavLinkProps = NavItem & {
  className?: string;
  footer?: JSX.Element;
};

type NavTooltipProps = {
  name: string;
};

function NavTooltip({ name }: NavTooltipProps) {
  return (
    <div className="text-xs leading-4 font-normal tracking-widest text-center w-15 py-1.5 text-gray-100">
      {name}
    </div>
  );
}

export default function NavLink({
  id,
  name,
  href,
  icon: Icon,
  shouldHighlight,
  className,
}: NavLinkProps) {
  const { asPath } = useRouter();
  const { isCollapsed } = React.useContext(DesktopSidebarContext);
  const navItemActive = shouldHighlight
    ? shouldHighlight(asPath)
    : defaultShouldHighlightNavItem(asPath, href);

  const navLinkContent = (
    <div className="flex flex-row items-center">
      <Icon
        className={classnames('flex-shrink-0 h-6 w-6', {
          'xl:mr-3': !isCollapsed,
        })}
        aria-hidden="true"
      />
      <span
        className={classnames(
          'text-sidebar-nav font-normal tracking-sidebar-nav',
          isCollapsed ? 'sr-only' : 'sr-only xl:not-sr-only'
        )}
      >
        {name}
      </span>
    </div>
  );

  return (
    <Link
      href={href}
      id={id}
      data-testid="link"
      className={classnames(
        'hover:text-white hover:no-underline relative group flex items-center h-13 leading-4',
        'flex-col justify-center',
        {
          'bg-current-nav-item text-white fill-current bg-blue-600':
            navItemActive,
          'text-gray-300 fill-current': !navItemActive,
          'p-3 xl:flex-row xl:justify-start': !isCollapsed,
        },
        className
      )}
    >
      <div className="flex flex-row">
        {isCollapsed ? (
          <Tooltip
            label={<NavTooltip name={name} />}
            margin={40}
            position="right"
            backgroundColor="gray"
          >
            {navLinkContent}
          </Tooltip>
        ) : (
          navLinkContent
        )}
      </div>
    </Link>
  );
}
