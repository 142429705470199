import { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';

export function camelCaseResponseInterceptor(
  response: AxiosResponse<any>
): AxiosResponse<any> | Promise<AxiosResponse<any>> {
  if (typeof response?.data !== 'object') {
    return response;
  }
  const convertedData = camelcaseKeys(response.data, { deep: true });
  response.data = convertedData;
  return response;
}
