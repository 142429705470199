import { AxiosRequestConfig } from 'axios';

const debug = require('@/lib/debug');
const log = debug.extend('api:client:pathParamInterceptor');

const PATH_PARAM_RE = /:([A-Za-z_][A-Za-z0-9_-]*)/g;

export function pathParamInterceptor(
  config: AxiosRequestConfig
): AxiosRequestConfig {
  const passedParamsObject = 'params' in config ? 'params' : 'data';
  if (!config.url || !config[passedParamsObject]) {
    return config;
  }
  log('url: %s', config.url);
  log('params: %O', config.params);
  const foundParams: Array<string> = [];
  // axios params can be either an object hash or a URLSearchParams
  const paramsIsObject = !(config.params instanceof URLSearchParams);
  // replace each instance in the path string with the value from params
  config.url = config.url.replace(
    PATH_PARAM_RE,
    (_: string, name: string): string => {
      if (
        paramsIsObject
          ? name in config[passedParamsObject] === false
          : !config[passedParamsObject].has(name)
      ) {
        throw new Error(
          `pathParamInterceptor: path param '${name}' from URL not included in params`
        );
      }
      foundParams.push(name);
      return paramsIsObject
        ? config[passedParamsObject][name]
        : config[passedParamsObject].get(name);
    }
  );
  // remove all found path params so they're not duplicated in the query string
  // by assigning to a new instance treating the original as immutable to avoid
  // side effects
  if (paramsIsObject) {
    config[passedParamsObject] = Object.fromEntries(
      Object.entries(config[passedParamsObject]).filter(
        (entry: [string, any]): boolean => !foundParams.includes(entry[0])
      )
    );
  } else {
    const newParams = new URLSearchParams();
    for (const [name, val] of config[passedParamsObject]) {
      if (!foundParams.includes(name)) {
        newParams.set(name, val);
      }
    }
    config[passedParamsObject] = newParams;
  }
  return config;
}
