import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useRollbar } from '@rollbar/react';
import { FunctionComponent, useEffect, useState } from 'react';

import { useAccountInfo } from '@/contexts/AccountInfoContext';
import { getFeaturesByPlan, getPlanById, FeatureByPlan } from '@/lib/utils';

type Props = {
  className?: string;
};

const CurrentPlanCard: FunctionComponent<Props> = ({ className = '' }) => {
  const [features, setFeatures] = useState<FeatureByPlan[]>();
  const rollbar = useRollbar();
  const accountInfo = useAccountInfo();

  const [planName, setPlanName] = useState('Free');

  useEffect(() => {
    const { planId } = accountInfo;

    const plan = getPlanById({ id: planId });
    if (!plan) {
      rollbar.error('Unable to find plan by id', { planId });
      return;
    }
    setPlanName(plan.name.split(/ /)[0]);
    const topFilteredFeatures = getFeaturesByPlan({ type: plan.type })
      ?.filter(({ value }) => value)
      .slice(0, 5);

    const filteredFeatures = topFilteredFeatures.filter(
      ({ name }) => name !== 'Included event volume'
    );
    setFeatures([
      {
        name: `${plan.includedOccurrences / 1000}K occurrences per month`,
      },
      ...filteredFeatures,
    ]);
  }, [accountInfo, rollbar]);

  return (
    <div
      className={`${className} border-2 border-gray-200 p-6 rounded-lg flex flex-col gap-4 bg-white`}
    >
      <h2 className="font-bold text-gray-700 text-2xl">{planName}</h2>

      <p className="text-gray-500 text-sm">
        Receive real-time alerts & keep track of issues
      </p>

      <p className="text-center text-gray-500 text-sm py-1.5">
        Your current plan
      </p>

      <ol className="flex flex-col list-image-none list-none gap-y-4">
        {features?.map(({ name }) => (
          <li
            className="grid gap-x-2 items-center list-image-none list-none text-gray-500 text-sm grid-cols-[20px_1fr]"
            key={name}
          >
            <CheckCircleIcon />

            {name}
          </li>
        ))}
      </ol>
    </div>
  );
};

export { CurrentPlanCard };
