import { GraphQLClient } from 'graphql-request';
import { useQuery, UseQueryResult } from 'react-query';

import { gqlClient } from '@/api';
import {
  GetAccountInfoDocument,
  GetAccountInfoLastAcctDocument,
  GetAccountInfoQuery,
  GetAccountInfoLastAcctQuery,
  AccountSlugType,
} from '@/generated/graphql';
import redirectUnauthorizedGraphqlToLogin from '@/lib/redirectUnauthorizedToLogin';
import { AccountInfo } from '@/types/account';

const debug = require('@/lib/debug');
const log = debug.extend('hooks:useAccounts');

export const ACCOUNTINFO_QUERY_KEY = 'accountInfo';

export function getAccountInfoQueryKey(accountSlug: string) {
  return [ACCOUNTINFO_QUERY_KEY, accountSlug];
}

// Depending on if you pass in a slug or not, the account will live in different places in
// the response object, but they will be returning the same fields
type AccountData =
  | GetAccountInfoQuery['account']
  | GetAccountInfoLastAcctQuery['viewer']['lastViewedAccount'];

/**
 * Transform the data into the AccountInfo type that was originally used when this was from the result of a mox
 * api call. The result of this hook is used all over the codebase, seems to make more sense to keep the original shape
 * rather than change the 100+ uses of this return value
 */
export function mapResponseToAccountInfo(
  viewer: GetAccountInfoLastAcctQuery['viewer'] | GetAccountInfoQuery['viewer'],
  account: AccountData | undefined | null
): AccountInfo | null {
  if (!account) {
    return null;
  }

  return {
    accountId: parseInt(account.id),
    accountSlug: account.slug,
    description: String(account.description),
    retentionPeriodDays: account.retentionPeriodDays
      ? account.retentionPeriodDays
      : undefined,
    isOrganization: account.slugType === AccountSlugType.Organization,
    isFreeTrial: account.subscription?.isDuringFreeTrial ?? false,
    isFreePlan: account.subscription?.isFreePlan ?? true,
    isSuspended: account.subscription?.isSuspended ?? false,
    isEnterprisePlan: account.subscription?.isEnterprisePlan ?? false,
    subscribedPlan: account.subscription?.subscribedPlan || '',
    planId: account.subscription?.planId || '',
    endDate: account.subscription?.endDate || '',
    dateCreated: account.dateCreated,
    onboarding: account.onboarding,
    user: {
      email: viewer.defaultEmail?.emailAddress || '',
      name: viewer.username,
      id: parseInt(viewer.id),
      // default shouldn't happen since only returns null in subscriptions
      hash: viewer.launchDarklyUserHash || '',
      isOwner: account.viewerPermissions.isOwner,
      isSuperadmin: viewer.isSuperadmin,
      isGuest: viewer.isGuest,
      // default should not happen since this only returns null if called in a paginated query
      canManageProjects: account.viewerPermissions.canManageProjects ?? false,
      dateCreated: viewer.dateCreated,
    },
    subscriptionBannerUsage: {
      showDeactivatedBanner:
        account.subscription?.subscriptionBannerUsage?.showDeactivatedBanner ??
        false,
      showDeactivationSoonBanner:
        account.subscription?.subscriptionBannerUsage
          ?.showDeactivationSoonBanner ?? false,
      accountCancelled:
        account.subscription?.subscriptionBannerUsage?.accountCancelled ??
        false,
    },
  };
}

export async function getAccountInfo(
  client: GraphQLClient,
  accountSlug?: string
): Promise<AccountInfo | null | undefined> {
  try {
    let accountInfo: AccountInfo | null;

    if (accountSlug) {
      const { viewer, account } = await client.request(GetAccountInfoDocument, {
        accountSlug,
      });
      accountInfo = mapResponseToAccountInfo(viewer, account);
    } else {
      const { viewer } = await client.request<GetAccountInfoLastAcctQuery>(
        GetAccountInfoLastAcctDocument
      );
      accountInfo = mapResponseToAccountInfo(viewer, viewer.lastViewedAccount);
    }

    return accountInfo;
  } catch (error) {
    // graphql-request does not support middleware, so we would have to create support for it ourselves
    // for now, useAccounts is called on all pages, so if this returns unauthorized we can redirect and our bases should
    // be covered
    redirectUnauthorizedGraphqlToLogin(error);
    throw error;
  }
}

export default function useAccounts(
  accountSlug: string = ''
): UseQueryResult<AccountInfo> {
  return useQuery(
    getAccountInfoQueryKey(accountSlug),
    async () => {
      const accountInfo = await getAccountInfo(gqlClient(), accountSlug);

      log('getAccountInfo returned: %O', accountInfo);
      return accountInfo;
    },
    {
      retry: 3,
    }
  );
}
