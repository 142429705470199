import { useRollbarPerson } from '@rollbar/react';
import { GraphQLClient } from 'graphql-request';
import Router from 'next/router';
import * as React from 'react';
import { QueryClient } from 'react-query';

import { gqlClient } from '@/api';
import { NotFound } from '@/components/common/NotFound';
import RbLoader from '@/components/common/RbLoader';
import { AccountInfoContext } from '@/contexts/AccountInfoContext';
import {
  GetAccountInfoDocument,
  useValidateSessionQuery,
  ValidateSessionDocument,
} from '@/generated/graphql';
import useAccounts, {
  getAccountInfoQueryKey,
  mapResponseToAccountInfo,
} from '@/hooks/useAccounts';
import { useAccountSlugFromPath } from '@/hooks/useAccountSlugFromPath';
import redirectUnauthorizedGraphqlToLogin from '@/lib/redirectUnauthorizedToLogin';
import { AccountInfo } from '@/types/account';

type AccountProviderProps = {
  children: React.ReactElement;
};

export function getSessionData(
  queryClient: QueryClient,
  gqlClient: GraphQLClient,
  accountSlug: string
): Promise<unknown>[] {
  const validateSessionKey = useValidateSessionQuery.getKey({
    accountSlug,
  });

  return [
    queryClient.prefetchQuery(getAccountInfoQueryKey(accountSlug), async () => {
      return gqlClient
        .request(GetAccountInfoDocument, {
          accountSlug,
        })
        .then(({ account, viewer }) =>
          mapResponseToAccountInfo(viewer, account)
        );
    }),
    queryClient.prefetchQuery(validateSessionKey, async () => {
      return gqlClient.request(ValidateSessionDocument, {
        accountSlug,
      });
    }),
  ];
}

export type AccountSuspendedProps = {
  accountIsSuspended: boolean;
  userIsAccountOwner: boolean;
};

export function getAccountSuspendedProps(
  queryClient: QueryClient,
  accountSlug: string
): AccountSuspendedProps {
  const accountInfoQueryKey = getAccountInfoQueryKey(accountSlug);

  const accountInfoData: AccountInfo | null | undefined =
    queryClient.getQueryData(accountInfoQueryKey);

  return {
    accountIsSuspended: accountInfoData?.isSuspended ?? false,
    userIsAccountOwner: accountInfoData?.user.isOwner ?? false,
  };
}

export default function AccountProvider({ children }: AccountProviderProps) {
  const accountSlug = useAccountSlugFromPath();
  const {
    isLoading: isUseAccountsLoading,
    data: accountInfo,
    error,
  } = useAccounts(accountSlug); // Don't fetch if we already have it

  const {
    isLoading: isUseValidateSessionQueryLoading,
    data: sessionInfo,
    error: sessionValidationError,
  } = useValidateSessionQuery(
    gqlClient(),
    {
      accountSlug: accountSlug,
    },
    {
      // we should recheck if the session is still valid
      // every 3 minutes
      enabled: process.env.NODE_ENV !== 'development',
      refetchInterval: 180000,
    }
  );

  useRollbarPerson({
    id: accountInfo?.user.id,
    username: accountInfo?.user.name,
    email: accountInfo?.user.email,
  });

  React.useEffect(() => {
    if (sessionValidationError) {
      redirectUnauthorizedGraphqlToLogin(sessionValidationError);
    }

    if (sessionInfo?.isSessionValid === false) {
      // If the session is not valid, redirect the user back to mox. After redirecting to mox, the event subscribers will
      // handle redirecting the user to the appropriate page based on the event subscribers in
      // https://github.com/rollbar/mox/blob/74b6893eef989e9a12f7dfb6afef2eddd5daec77/mox/pyramid/events/__init__.py
      Router.push(
        `${process.env.NEXT_PUBLIC_ROLLBAR_BASE_URL}/${accountSlug}/all/items`
      );
    }
  }, [accountSlug, sessionInfo, sessionValidationError]);

  if (isUseAccountsLoading || isUseValidateSessionQueryLoading) {
    return (
      <div className="h-screen">
        <RbLoader />
      </div>
    );
  }

  if (error) throw error;
  if (!accountInfo) {
    return <NotFound />;
  }

  return (
    <AccountInfoContext.Provider value={accountInfo}>
      {children}
    </AccountInfoContext.Provider>
  );
}
