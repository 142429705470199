import { DataProcessingAddendum } from '@/types/dataProcessingAddendum';

import { apiClient } from './client';

const ENDPOINT_DPA_AUDIT_TRAIL = '/account/:accountSlug/verify_dpa_signed';

export async function getDataProcessingAddendum(
  accountSlug: string
): Promise<DataProcessingAddendum[]> {
  const result = await apiClient().get(`/account/${accountSlug}/dpa`);
  const responseData = result.data.map((dpa: any) => ({
    date: dpa.signedTime,
    name: dpa.name,
    signed: dpa.signed,
    url: dpa.url,
  }));
  return responseData;
}

export type LogDpaAuditTrailData = {
  version: string;
  accountSlug: string;
};
export async function logDpaAuditTrail({
  version,
  accountSlug,
}: LogDpaAuditTrailData): Promise<void> {
  // call mgmt
  await apiClient().post(ENDPOINT_DPA_AUDIT_TRAIL, {
    version,
    accountSlug,
  });
  return;
}
