/* This example requires Tailwind CSS v2.0+ */
import * as React from 'react';

import UpsellModal from '@/components/common/UpsellModal';
import { UpsellProvider } from '@/components/common/UpsellProvider';
import NotificationContextProvider from '@/contexts/NotificationContext';
import { UpsellFeature } from '@/generated/graphql';
import ToastsContextProvider from '@/rollbar-ui/contexts/ToastsContext';

import * as Sidebar from './Sidebar';

type AppShellProps = {
  children: React.ReactNode;
};

export default function AppShell({ children }: AppShellProps) {
  const [showUpsellModal, setShowUpsellModal] = React.useState(false);
  const [showFeatureCard, setShowFeatureCard] =
    React.useState<React.ReactNode>();
  const [upsellFeature, setUpsellFeature] = React.useState<UpsellFeature>();
  const handleClose = React.useCallback(() => {
    setShowUpsellModal(false);
  }, [setShowUpsellModal]);
  const contextValue = React.useMemo(() => {
    return {
      setShowModal: setShowUpsellModal,
      showModal: showUpsellModal,
      FeatureCard: showFeatureCard,
      setFeatureCard: setShowFeatureCard,
      handleClose,
      upsellFeature: upsellFeature,
      setUpsellFeature: setUpsellFeature,
    };
  }, [showUpsellModal, showFeatureCard, handleClose, upsellFeature]);
  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      {/* Static sidebar for desktop */}
      <Sidebar.Desktop />

      <div className="flex flex-col w-0 flex-1">
        <main className="flex-1 relative focus:outline-none">
          <ToastsContextProvider>
            <NotificationContextProvider>
              <UpsellProvider value={contextValue}>
                {children}
                {showUpsellModal && (
                  <UpsellModal
                    onClose={handleClose}
                    FeatureCard={showFeatureCard}
                    upsellFeature={upsellFeature}
                  />
                )}
              </UpsellProvider>
            </NotificationContextProvider>
          </ToastsContextProvider>
        </main>
      </div>
    </div>
  );
}
