import { XMarkIcon } from '@heroicons/react/24/solid';
import { FunctionComponent } from 'react';

export type Props = {
  hasCloseButton?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  testId?: string;
  title?: React.ReactNode;
  variant?: any;
};

const ModalHeader: FunctionComponent<Props> = (props) => {
  const {
    hasCloseButton = false,
    onCancel,
    onClose = onCancel,
    title,
    variant,
  } = props;

  const titleColor: string =
    variant === 'destruct' ? 'text-red-700' : 'text-grey-700';

  return (
    <div className="flex justify-between pb-6 pt-2">
      {title && <div className={`px-2 font-bold ${titleColor}`}>{title}</div>}

      {hasCloseButton && (
        <button onClick={onClose} className="px-2 text-gray-700">
          <span className="sr-only">Close modal</span>
          <XMarkIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      )}
    </div>
  );
};

export { ModalHeader };
