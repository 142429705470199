import EmailPasswordWebJs from 'supertokens-web-js/recipe/emailpassword';
import SessionWebJs from 'supertokens-web-js/recipe/session';
import ThirdPartyWebJs from 'supertokens-web-js/recipe/thirdparty';
import { SuperTokensConfig } from 'supertokens-web-js/types';

import { appInfo } from './appInfo';

export const frontendConfig = (): SuperTokensConfig => {
  return {
    appInfo,
    recipeList: [
      ThirdPartyWebJs.init(),
      EmailPasswordWebJs.init(),
      SessionWebJs.init(),
    ],
  };
};
