import classnames from 'classnames';
import gravatar from 'gravatar';
import * as React from 'react';
import { useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export type AvatarProps = {
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  email?: string;
  className?: string;
  defaultIcon?: 'retro' | 'mp';
};

export default function Avatar({
  size = 'xs',
  email,
  className,
  defaultIcon = 'retro',
}: AvatarProps) {
  const convertSize = (size: any) => {
    switch (size) {
      case 'xxs':
        return 40;
      case 'xs':
        return 48;
      case 'sm':
        return 64;
      case 'md':
        return 80;
      case 'lg':
        return 96;
      case 'xl':
        return 112;
    }
  };
  const gravatarHash = useMemo(
    () =>
      gravatar.url(email || '', {
        protocol: 'https',
        d: defaultIcon,
        s: `${convertSize(size)}`,
      }),
    [email, size, defaultIcon]
  );
  return (
    <div>
      <LazyLoadImage
        placeholder={
          <span
            data-testid="avatar-placeholder"
            className={classnames(
              'inline-block rounded-full overflow-hidden bg-gray-100',
              {
                'h-5 w-5': size === 'xxs',
                'h-6 w-6': size === 'xs',
                'h-8 w-8': size === 'sm',
                'h-10 w-10': size === 'md',
                'h-12 w-12': size === 'lg',
                'h-14 w-14': size === 'xl',
              },
              className
            )}
          >
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
        }
        data-testid="avatar"
        className={classnames(
          'inline-block rounded-full',
          {
            'h-6 w-6': size === 'xs',
            'h-8 w-8': size === 'sm',
            'h-10 w-10': size === 'md',
            'h-12 w-12': size === 'lg',
            'h-14 w-14': size === 'xl',
          },
          className
        )}
        src={gravatarHash}
        alt=""
      />
    </div>
  );
}
